<template>
    <v-container class="pa-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
            <v-icon>mdi-alert</v-icon>
            Reported Risk Alert
        </div>
        <v-row>
            <v-col class="col-4">
                <v-card elevation="0">
                <v-list v-show="ReportedRisk" three-line>
                    <v-list-item-group v-model="selectedReport">
                        <v-list-item link v-show="ReportedRisk" v-for="reports in ReportedRisk" :key="reports['.key']">
                        <v-list-item-content>
                            <v-list-item-title >{{reports.RiskName}}</v-list-item-title>
                            <v-list-item-subtitle >{{reports.IncidentDetails}}</v-list-item-subtitle>
                        </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{$moment(reports.timestamp.toDate()).fromNow()}}</v-list-item-action-text>
                                <v-icon
                                    v-if="!reports.isVerified"
                                    color="'grey lighten-1'"
                                    >
                                    mdi-checkbox-blank-circle-outline
                                </v-icon>
                                <v-icon
                                v-else
                                color="green darken-3"
                                >
                                mdi-check-circle
                                </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                </v-card>   
            </v-col>
            <v-col class="col">
                <v-card elevation="0" v-if="returnSelected !== null" class="px-8 py-4">
                    <v-list three-line class="pb-0">
                        <v-list-item-content>
                            <v-list-item-title v-if="returnSelected" class="text-h5 font-weight-bold">
                                <v-icon
                                    v-if="!returnSelected.isVerified"
                                    color="'grey lighten-1'"
                                    >
                                    mdi-checkbox-blank-circle-outline
                                </v-icon>
                                <v-icon
                                v-else
                                color="green darken-3"
                                >
                                mdi-check-circle
                                </v-icon>
                                {{returnSelected.RiskName}}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="returnSelected">
                                <v-chip v-for="dept in returnSelected.Department" :key="dept" small class="mr-2 mb-2">{{dept}}</v-chip>
                                <br>
                                <span class="text-caption">{{returnSelected.userID}} <br>
                                <span  v-show="returnSelected.isVerified">Reported: </span>
                                {{$moment(returnSelected.timestamp.toDate()).fromNow()}}
                                
                                <span v-if="returnSelected.isVerified && returnSelected.verifiedOn !== undefined"><br>
                                    Verified On: {{$moment(returnSelected.verifiedOn.toDate()).fromNow()}}
                                </span>
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>   
                        <v-list-item-action>

                            <v-list-item-action-text  v-if="returnSelected">

                            </v-list-item-action-text>
                        </v-list-item-action>                     
                    </v-list>
                    <v-card-text v-if="returnSelected">
                        <v-expansion-panels accordion flat class="elevation-0 grey my-4">
                        <v-expansion-panel flat class="elevation-0 grey lighten-2">
                            <v-expansion-panel-header class="elevation-0">
                            Risk Information Guide
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="elevation-0">
                            <div class="pa-4 mb-4 text-caption grey lighten-4">
                            <span>
                                <b>Risk Classification:</b> {{returnSelected.RiskClassification.RiskClassification}}
                            </span>
                            <br>
                                <b>Universal Causes:</b>
                            <br>
                            <span v-for="(causes,cidx) in returnSelected.UniversalCauses" :key="cidx+$lodash.uniqueId()">
                                {{causes}} <br>
                            </span>
                            <br>
                            <b>Universal Impacts:</b>
                            <br>
                            <span v-for="(impacts,iidx) in returnSelected.UniversalImpacts" :key="iidx+$lodash.uniqueId()">
                                {{impacts}} <br>
                            </span>
                            </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Affected Branch</v-col>
                            <v-col>{{returnSelected.AffectedBranch}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Impact / Amount</v-col>
                            <v-col>{{returnSelected.ImpactAmount}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">With Insurance ?</v-col>
                            <v-col>
                                 <v-icon class="green--text" v-if="returnSelected.WithInsurance">mdi-check-circle</v-icon>
                                 <v-icon v-else class="red--text">mdi-close-circle</v-icon>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Date of Occurence</v-col>
                            <v-col>{{returnSelected.DateOccurrence}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Date Detection</v-col>
                            <v-col>{{returnSelected.DateDetection}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Date Reported</v-col>
                            <v-col>{{returnSelected.DateReported}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Reason of Delay (of Reporting)</v-col>
                            <v-col>{{returnSelected.ReasonDelay}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Commited By:</v-col>
                            <v-col> <v-chip v-for="dept in returnSelected.CommitedBy" :key="dept" small class="mr-2 mb-2">{{dept}}</v-chip></v-col>
                        </v-row>
                        <v-row>
                            <v-col class="primary--text font-weight-bold">Incident Details</v-col>
                            <v-col> {{returnSelected.IncidentDetails}}</v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions  v-if="!returnSelected.isVerified">
                        <v-row>
                            <v-btn class="col mr-2" color="grey" text @click="InvalidReport" v-show="!returnSelected.isSeen">
                                <v-icon class="mr-2"> mdi-close </v-icon>  Invalid Report
                            </v-btn>
                            <v-btn class="col" color="primary" @click="verifyReport" v-show="!returnSelected.isSeen">
                                <v-icon class="mr-2"> mdi-check </v-icon>  Verify Report
                            </v-btn>

                        </v-row>
                        <!-- <v-btn color="primary" block @click="markAsRead()" v-show="!returnSelected.isSeen">
                            <v-icon class="mr-2"> mdi-star </v-icon>  mark as read
                        </v-btn> -->
                    </v-card-actions>
                </v-card>
                <v-card v-else elevation="0">
                    <v-card-text class="text-h6 grey--text text-center pa-12">
                        There is selected report.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
     
    </v-container>
</template>
<script>
export default {
    data: () => ({
        selectedReport: null
    }),
    firestore(){
        return {
            ReportedRisk: this.$db.collection('ReportedRisk').orderBy('timestamp','desc'),
        }
    },
    computed:{
        returnSelected(){
            if(this.selectedReport == null) return null
            return this.ReportedRisk[this.selectedReport]
        }
    },
    methods:{
        markAsRead(){
            let selected = this.returnSelected
            console.log(selected);
            this.$db.collection('ReportedRisk').doc(selected['.key']).update({
                isSeen: true
            }).then(()=>{
                this.$dialog.notify.success(`Report - Mark as Read`, {
                    position: 'bottom-right',
                    timeout: 3000
                })                 
            })
        },
        async verifyReport(){
            let self = this
            // confirm
            //and prompy a message after you verify , this will alert all departments included in the report.
            // save to another table for department alerts
            // update item to isverified
            const confirm = await this.$dialog.confirm({
                text: `Do you want to verify this report? Once verified, this will send a alert to the departments included in this report.`,
                title: `Confirm Verifying of Report`
            })
            if(!confirm){
                return
            }   
            let newAlert = {...self.returnSelected}
            delete newAlert['.key']
            newAlert.reportKey = self.returnSelected['.key']
            delete newAlert.timestamp
            newAlert.reportDateTime = self.returnSelected.timestamp
            newAlert.timestamp = self.$firebase.firestore.FieldValue.serverTimestamp()
            newAlert.verifiedBy = self.$store.getters['useraccount/isAuthenticated'].email
            
            console.log(newAlert,'newAlert');
            self.$db.collection('RiskAlerts').add(newAlert)
            .then(()=>{
                let key = self.returnSelected['.key']
                let remove = {...self.returnSelected}
                remove.isVerified = true
                remove.verifiedOn = self.$firebase.firestore.FieldValue.serverTimestamp()
                remove.verifiedBy = self.$store.getters['useraccount/isAuthenticated'].email            
                delete remove['.key']
                self.$db.collection('ReportedRisk').doc(key).set(remove)
                .then(()=>{
                    self.$dialog.notify.success(`Success Verifying Risk Report`, {
                        position: 'bottom-right',
                        timeout: 3000
                    }) 
                    self.$dialog.notify.success(`Success Sending Risk Alerts`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                        
                }).catch(err=>{
                    self.$dialog.notify.success(`Error : ${err}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                      
                })
            }).catch(err=>{
                self.$dialog.notify.success(`Error : ${err}`, {
                    position: 'bottom-right',
                    timeout: 3000
                })                      
            })
        },
        async InvalidReport(){
            const confirm = await this.$dialog.confirm({
                text: `Do you want to confirm that this report INVALID? Once confirmed, this will DELETE THE REPORT in the database.`,
                title: `Confirm Invalid Report`
            })
            if(!confirm){
                return
            }  
            
            let key = this.returnSelected['.key']
            this.$db.collection('ReportedRisk').doc(key).delete()
            .then(()=>{
                this.$dialog.notify.success(`Success Removing Invalid Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                })                  
            }).catch(err=>{
                this.$dialog.notify.success(`Error : ${err}`, {
                    position: 'bottom-right',
                    timeout: 3000
                })                      
            })

        }
    }

}
</script>